.content-page .dx-card {
	height: 100vh;
	margin: auto auto;
	padding: 10px;
	flex-grow: 0;
}

.screen-x-small .content-page .dx-card {
	width: 100%;
	height: 100%;
	border-radius: 0;
	box-shadow: none;
	margin: 0;
	border: 0;
	flex-grow: 1;
}

.content-page .dx-card .header {
	margin-bottom: 30px;
}

.content-page .dx-card .header .title {
	color: #363640;
	line-height: 28px;
	font-weight: 500;
	font-size: 24px;
}

.content-page .dx-card .header .description {
	color: rgba(255, 255, 255, 0.7);
	line-height: 18px;
}