.centered {
    clear: both;
    width: 100%;
    padding: 70px 0;
    text-align: center;
}

.refresh-button {
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    color: #009ddf;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.small {
    font-weight: 400;
    font-size: 14px;
}