.side-nav-inner-toolbar {
	width: 100%;
	border-right: 5px;
	border-color: azure;
}

.container {
	height: 100%;
	flex-direction: column;
	display: flex;
}

.layout-body {
	flex: 1;
	min-height: 0;
}

.content {
	flex-grow: 1;
}

#navigation-header {
	background-color: #009ddf;
	box-shadow: 0 1px 3px rgba(200, 219, 247, 0.12), 0 1px 2px rgba(200, 219, 247, 0.24);
}

#navigation-header .menu-button .dx-icon {
	color: #fff;
}

.screen-x-small #navigation-header {
	padding-left: 20px;
}

.dx-theme-generic #navigation-header {
	padding-top: 10px;
	padding-bottom: 10px;
}