.action-area{    
    top: 70px;    
    z-index: 99;
    text-align: right;    
    margin-right: 50px;
    margin-left: auto;
}

.action-button{
    margin-left: 5px;      
}