.user-info {
	 display: flex;
	 align-items: center;
}
 .dx-toolbar-menu-section .user-info {
	 padding: 10px 6px;
	 border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
 .user-info .image-container {
	 overflow: hidden;
	 border-radius: 50%;
	 height: 30px;
	 width: 30px;	
	 border: 1px solid rgba(0, 0, 0, 0.1);
	 box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}
 .user-info .image-container .user-image {
	 width: 100%;
	 height: 100%;
}
 .user-info .user-name {
	 font-size: 14px;
	 color: #fff;
	 margin: 0 9px;
}
 .user-panel .dx-list-item .dx-icon {
	 vertical-align: middle;
	 color: #fff;
	 margin-right: 16px;
}
 .user-panel .dx-rtl .dx-list-item .dx-icon {
	 margin-right: 0;
	 margin-left: 16px;
}
 .dx-context-menu.user-menu.dx-menu-base.dx-rtl .dx-submenu .dx-menu-items-container .dx-icon {
	 margin-left: 16px;
}
 .dx-context-menu.user-menu.dx-menu-base .dx-submenu .dx-menu-items-container .dx-icon {
	 margin-right: 16px;
}
 .dx-context-menu.user-menu.dx-menu-base .dx-menu-item .dx-menu-item-content {
	 padding: 3px 15px 4px;
}
 .dx-theme-generic .user-menu .dx-menu-item-content .dx-menu-item-text {
	 padding-left: 4px;
	 padding-right: 4px;
}
 