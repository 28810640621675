.server-error.container{
    padding-left: 15px;
    padding-right: 15px;
}

.server-error.message {
    color: red
}

.server-error.stack-trace {
    color: teal
}

.server-error.details{
    margin-top: 10px
}