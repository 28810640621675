
.note.note-red {  
    background-color: rgba(240,91,65,.2);
    padding: 20px;    
    font-size: 14px;    
    margin-bottom: 10px;
}

.note-header-red {
    font-weight: bold;
    color: #f05b41;
    text-transform: uppercase;
}


.note.note-yellow {  
    background-color: rgba(240, 237, 65, 0.2);
    padding: 20px;    
    font-size: 14px;    
    margin-bottom: 10px;
}

.note-header-yellow {
    font-weight: bold;
    color: #edf041;
    text-transform: uppercase;
}

.note.note-green {  
    background-color: rgba(65, 240, 88, 0.2);
    padding: 20px;    
    font-size: 14px;    
    margin-bottom: 10px;
}

.note-header-green {
    font-weight: bold;
    color: #90f041;
    text-transform: uppercase;
}


.note.note-blue{  
    background-color: rgba(65, 155, 240, 0.2);
    padding: 20px;    
    font-size: 14px;    
    margin-bottom: 10px;
}

.note-header-blue {
    font-weight: bold;
    color: #418df0;
    text-transform: uppercase;
}
   