body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.create-account-card {
	width: 650px;
	height: 400px;
}

.dx-item-content.dx-multiview-item-content {
	overflow: hidden;
	box-shadow: 0 1px 3px rgb(3 169 244 / 12%), 0 1px 2px rgb(3 169 244 / 24%);
	border-radius: 4px;
	margin: 2px 2px 3px;
}

.dx-form-group-with-caption>.dx-form-group-content {
	border-top: 1px solid #363640;
	padding-bottom: 20px;
	padding-top: 19px;
	margin-top: 6px;
}

/* .dx-form-group-caption {
	font-size: 20px;
	color: #009ddf;
} */

/* .dx-field-item-label-text {
	color: rgba(3, 169, 244, .6);
} */

/* .dx-field-item-optional .dx-label>span::after,
.dx-field-item-optional-mark,
.dx-field-item-required .dx-label>span::after,
.dx-field-item-required-mark {
	color: rgba(3, 169, 244, .6);
} */

.dx-texteditor.dx-editor-filled {
	background-color: rgba(3, 169, 244, .04);
}

.dx-multiview-item-content {
	width: auto;
	height: 100%;
}

.grid-lower-buttons {
	margin-top: 35px;
	margin-bottom: 25px;
}

.app {
	display: flex;
	height: 100%;
	width: 100%;
}

.app .content {
	line-height: 1.5;
}

.app .content h2 {
	font-size: 30px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.app .content-block {
	margin-left: 40px;
	margin-right: 40px;
	margin-top: 20px;
}

.screen-x-small .content-block {
	margin-left: 20px;
	margin-right: 20px;
}

.responsive-paddings {
	padding: 20px;
}

.screen-large .responsive-paddings {
	padding: 40px;
}

.dx-card.wide-card {
	border-radius: 0;
	margin-left: 0;
	margin-right: 0;
	border-right: 0;
	border-left: 0;
}

.with-footer>.dx-scrollable-wrapper>.dx-scrollable-container>.dx-scrollable-content {
	height: 100%;
}

.with-footer>.dx-scrollable-wrapper>.dx-scrollable-container>.dx-scrollable-content>.dx-scrollview-content {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

.stratum-grid>div>div>.dx-toolbar {
	background-color: transparent;
}

.dx-datagrid-edit-popup .dx-popup-content {
	padding: 25px;
}

html,
body {
	margin: 0px;
	min-height: 100%;
	height: 100%;
}

#root {
	height: 100%;
}

* {
	box-sizing: border-box;
}