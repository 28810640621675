.side-navigation-menu {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	height: 100%;
	width: 250px !important;
}

.side-navigation-menu .menu-container {
	min-height: 100%;
	display: flex;
	flex: 1;
}

.side-navigation-menu .menu-container .dx-treeview {
	white-space: nowrap;
}

.side-navigation-menu .menu-container .dx-treeview .dx-treeview-item {
	padding-left: 0;
	padding-right: 0;
}

.side-navigation-menu .menu-container .dx-treeview .dx-treeview-item .dx-icon {
	width: 60px !important;
	margin: 0 !important;
}

.side-navigation-menu .menu-container .dx-treeview .dx-treeview-node {
	padding: 0 0 !important;
}

.side-navigation-menu .menu-container .dx-treeview .dx-treeview-toggle-item-visibility {
	right: 10px;
	left: auto;
}

.side-navigation-menu .menu-container .dx-treeview .dx-rtl .dx-treeview-toggle-item-visibility {
	left: 10px;
	right: auto;
}

.side-navigation-menu .menu-container .dx-treeview .dx-treeview-node[aria-level='1'] {
	font-weight: bold;
	border-bottom: 1px solid #efeff3;
}

.side-navigation-menu .menu-container .dx-treeview .dx-treeview-node[aria-level='2'] .dx-treeview-item-content {
	font-weight: normal;
	padding: 0 60px;
}


.side-navigation-menu .menu-container .dx-treeview .dx-treeview-node-container .dx-treeview-node.dx-state-selected:not(.dx-state-focused)>.dx-treeview-item {
	background: #e8f3f9;
}

.side-navigation-menu .menu-container .dx-treeview .dx-treeview-node-container .dx-treeview-node.dx-state-selected>.dx-treeview-item>div>i.dx-icon {
	color: #009ddf;
}

.side-navigation-menu .menu-container .dx-treeview .dx-treeview-node-container .dx-treeview-node.dx-state-selected>.dx-treeview-item.dx-state-hover>div>span {
	color: #009ddf;
}

.side-navigation-menu .menu-container .dx-treeview .dx-treeview-node-container .dx-treeview-node.dx-state-selected>.dx-treeview-item * {
	color: #363640;
}

.side-navigation-menu .menu-container .dx-treeview .dx-treeview-node-container .dx-treeview-node:not(.dx-state-focused)>.dx-treeview-item.dx-state-hover {
	background-color: #363640;
}

.side-navigation-menu .menu-container .dx-theme-generic .dx-treeview .dx-treeview-node-container .dx-treeview-node.dx-state-selected.dx-state-focused>.dx-treeview-item * {
	color: inherit;
}

.dx-drawer-overlap.pre-init-blink-fix .dx-drawer-content {
	padding-left: 60px;
}

.dx-drawer-overlap.pre-init-blink-fix .dx-drawer-panel-content.dx-overlay::before {
	content: "";
	width: 60px;
	height: 100%;
	display: inline-block;
	position: absolute;
	background-color: #363640;
}

.dx-drawer-overlap.pre-init-blink-fix .dx-overlay-content {
	width: 60px !important;
}