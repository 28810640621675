.default-dropdown-button {
    background-color: #009ddf;
}

.default-dropdown-button-table {
    display: table;
}

.default-dropdown-button-table-cell {
    display: table-cell;
}

.default-dropdown-button-w-100 {
    width: 100%;
}

.default-dropdown-button-tar {
    text-align: right;
}

.clearance-header {
    overflow: hidden;
    font-size: 18px;
    box-shadow: 0 1px 3px rgb(3 169 244 / 12%), 0 1px 2px rgb(3 169 244 / 24%);
    border-radius: 4px;
    background-color: #fff;
    padding: 5px;
    padding-left: 10px;
    margin: 2px 2px 3px;
}

.clearance-expanded {
    box-shadow: 0 1px 5px rgb(3 169 244 / 50%), 0 1px 5px rgb(3 169 244 / 100%);
}

.clearance-buttons {
    text-align: right;
}

.clearance-center {
    padding-top: 5px;
}

.clearance-line {
    border-top: 2px solid;
    border-color: rgb(3 169 244 / 24%);
}

.mrn-clearance-box {
    padding: 10px;
}

.mrn-clearance-number {      
    padding: 10px;
}