.create-account-form .selected-plan-details {
    display: flex;
    padding: 1em;
}

.create-account-form .selected-plan-details .plan-details {
    display: flex;
    flex-grow: 1; 
    align-items: center;   
    margin: 0px 10px;
    
}

.plan-details .plan-name {
    font-weight: bold;
    font-size: 20px;
}

.plan-details .plan-price {
    font-size: 20px;
    margin-left: auto;
}
