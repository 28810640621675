.not-found {
    text-align: center;
}

.not-found-button {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #009ddf;
    background-color: transparent;
    border: none;
    cursor: pointer;
}