.plan-card {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 30px;
	margin: 20px;
	background-color: #f7fafd;
}

.plan-card .header {
	margin-bottom: 20px;
}

.plan-card .header .title {
	color: #363640;
	line-height: 28px;
	font-weight: 500;
	font-size: 30px;
}