.header-component {
	flex: 0 0 auto;
	z-index: 1;
	box-shadow: 0 1px 3px rgba(200, 219, 247, 0.12), 0 1px 2px rgba(200, 219, 247, 0.24);
}

.header-component .dx-toolbar .dx-toolbar-item.menu-button>.dx-toolbar-item-content .dx-icon {
	color: #363640;
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
	padding: 0 40px;
}

.screen-x-small .dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
	padding: 0 20px;
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
	width: 60px;
	text-align: center;
	padding: 0;
}

.header-title .dx-item-content {
	padding: 0;
	margin: 0;
}

.dx-theme-generic .dx-toolbar {
	padding: 10px 0;
}

.dx-theme-generic .user-button>.dx-button-content {
	padding: 3px;
}

.user-button.authorization {
	min-width: 210px;
}